<template>
  <b-modal
    v-model="_state"
    size="lg"
    footer-class="border-top-0"
    centered
    no-close-on-esc
    no-close-on-backdrop
    @show="resetData"
    @ok="onSubmit"
    @cancel="onCancel"
  >
    <template v-slot:modal-title>
      <h5 class="text-secondary mb-0">
        {{ $t("page.groupManagement.newUser") }}
      </h5>
    </template>

    <template v-slot:default>
      <search-users :selectedUsers.sync="selectedUsers" />
    </template>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-dark" @click="cancel">
        {{ $t("button.cancel") }}
      </b-button>
      <b-button
        class="text-white"
        variant="primary"
        :disabled="!isUserSelected"
        @click="ok"
      >
        {{ $t("button.add") }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import SearchUsers from "./SearchUsers";

export default {
  components: {
    SearchUsers
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedUsers: []
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    isUserSelected() {
      return this.selectedUsers.length > 0;
    }
  },
  methods: {
    async resetData() {
      await this.$store.dispatch("groups/retrieveGroupUsers");
      this.selectedUsers = [];
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onSubmit() {
      this.$emit("onSubmit", this.selectedUsers);
    },
    setModalState(value) {
      this.$emit("update:state", value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
