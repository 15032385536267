<template>
  <div>
    <!-- <div v-if="results.length > 0" class="text-right mb-2">
      Selected : {{ selectedUsers.length }}/{{ results.length }}
    </div> -->
    <b-input-group>
      <b-form-input
        v-model="searchText"
        class="search__input mb-3"
        :placeholder="$t('page.groupManagement.insertUser.inputPlaceholder')"
        debounce="500"
      />

      <b-spinner
        v-if="loading"
        class="loading__spinner"
        label="Spinning"
        variant="primary"
        small
      />
    </b-input-group>

    <b-list-group class="search__results">
      <template v-if="results.length > 0">
        <result-search-user
          v-for="(item, index) in results"
          :key="index"
          :user="item"
          :selectedUsers="selectedUsers"
          @onSelect="addUser"
        />
      </template>
      <div v-else-if="hasResults" class="mt-3 text-center">
        {{ $t("page.groupManagement.insertUser.noResults") }}
      </div>
      <div v-else-if="!loading" class="mt-3 text-center">
        {{ $t("page.groupManagement.insertUser.defaultMsg") }}
      </div>
    </b-list-group>
  </div>
</template>

<script>
import ResultSearchUser from "./ResultSearchUser";

export default {
  components: {
    ResultSearchUser
  },
  props: {
    selectedUsers: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    searchText: {
      handler: function(value) {
        if (value.length > this.minInputLength) this.apiSearch();
        else this.results = [];

        this._selectedUsers = [];
      },
      immediate: false
    }
  },
  data() {
    return {
      minInputLength: 2,
      searchText: "",
      loading: false,
      results: []
    };
  },
  computed: {
    hasResults() {
      return (
        !this.loading &&
        this.searchText.length > this.minInputLength &&
        this.results.length === 0
      );
    },
    members() {
      return this.$store.getters["groups/getGroupUsers"].map(
        member => member.user._id
      );
    },
    _selectedUsers: {
      get() {
        return this.selectedUsers;
      },
      set(value) {
        this.$emit("update:selectedUsers", value);
      }
    }
  },
  methods: {
    async apiSearch() {
      try {
        this.loading = true;
        const users = await this.$store.dispatch("groups/searchUsers", {
          searchText: this.searchText
        });
        this.results = users.filter(user => {
          if (!this.members.includes(user._id)) return user;
        });
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    localSearch() {
      console.log("local search");
      this.loading = true;
      setTimeout(() => (this.loading = false), 3000);
    },
    addUser(userId) {
      this._selectedUsers.splice(0, 1);
      this._selectedUsers.push(userId);
      // const index = this._selectedUsers.indexOf(userId);
      // if (index === -1) {
      //   this._selectedUsers.push(userId);
      // } else {
      //   this._selectedUsers.splice(index, 1);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.search__input {
  padding-right: 50px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.loading__spinner {
  position: absolute;
  top: 12px;
  right: 18px;
  z-index: 3;
}
.search__results {
  height: 300px;
  overflow-y: scroll;
}
</style>
