<template>
  <div>
    <h6 class="mb-2">{{ $t("uploader.dropFile") }}</h6>
    <div
      class="upload-section-wrapper rounded bg-gray-light"
      :class="{ 'upload-box-shadow': dragoverActive }"
    >
      <div
        class="upload-section rounded h-100"
        @drop.prevent="uploadLocalFiles"
        @dragover.prevent="dragover"
        @dragenter="dragoverActive = true"
        @dragleave="dragoverActive = false"
      >
        <b-row class="no-gutters h-100 justify-content-center" align-v="center">
          {{ $t("uploader.uploadFile") }}
        </b-row>
      </div>
    </div>
    <h6 class="my-2">{{ $t("uploader.selectFile") }}</h6>
    <b-button class="text-white" size="sm" @click="openUploadDialog">
      {{ $t("uploader.chooseFile") }}
    </b-button>

    <file-uploader
      ref="uploader"
      :fileTypes="fileTypes"
      :multiple="multiple"
      @setFiles="addFiles"
    />
  </div>
</template>

<script>
import FileUploader from "./BaseFileUploader";
export default {
  props: {
    fileTypes: {
      type: String,
      default: "*"
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragoverActive: false
    };
  },
  components: {
    FileUploader
  },
  methods: {
    openUploadDialog() {
      this.$refs.uploader.openSelectFileWindow();
    },
    addFiles(files) {
      this.$emit("addFiles", files);
    },
    dragover(event) {
      event.dataTransfer.dropEffect = "move";
    },
    uploadLocalFiles(e) {
      const files = e.dataTransfer.files;
      this.addFiles(files);
      this.dragoverActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-section-wrapper {
  height: 100px;
  border: 2px dashed var(--gray);
  padding: 2px;
}
.upload-box-shadow {
  box-shadow: inset 0 0 20px 1px var(--gray);
}
</style>
