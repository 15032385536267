import { render, staticRenderFns } from "./BaseFileUploader.vue?vue&type=template&id=5fc7b1c8&scoped=true&"
import script from "./BaseFileUploader.vue?vue&type=script&lang=js&"
export * from "./BaseFileUploader.vue?vue&type=script&lang=js&"
import style0 from "./BaseFileUploader.vue?vue&type=style&index=0&id=5fc7b1c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../administrator-console/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc7b1c8",
  null
  
)

export default component.exports