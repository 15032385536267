<template>
  <input
    v-bind="$attrs"
    class="file-input"
    :accept="fileTypes"
    :multiple="multiple"
    ref="fileInput"
    type="file"
    name="file-input"
    @change="uploadLocalFiles"
  />
</template>

<script>
export default {
  props: {
    fileTypes: {
      type: String,
      default: "image/*"
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    uploadLocalFiles(e) {
      this.$emit("setFiles", e.target.files);
      this.$refs.fileInput.value = "";
    },
    openSelectFileWindow() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.file-input {
  display: none;
}
</style>
