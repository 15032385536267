<template>
  <b-list-group-item
    class="clickable rounded-0"
    :class="isSelected && 'bg-gray-light'"
    @click="onSelect"
  >
    <b-row class="no-gutters align-items-center ml-n3">
      <b-col class="col-auto ml-3">
        <b-avatar
          class="avatar mr-3"
          :src="$_getUserProfilePhoto(user.profile)"
        />
      </b-col>
      <b-col class="col-auto ml-3">
        <div class="text-primary">{{ $_getUserFullName(user.profile) }}</div>
        <div>{{ user.email }}</div>
      </b-col>
      <b-col v-if="isSelected" class="col-auto ml-auto">
        <span aria-hidden="true">&check;</span>
        <span class="sr-only">Selected</span>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import userMixin from "../../mixins/userMixin";

export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    selectedUsers: {
      type: Array,
      default: () => []
    }
  },
  mixins: [userMixin],
  computed: {
    isSelected() {
      return this.selectedUsers.includes(this.user._id);
    }
  },
  methods: {
    onSelect() {
      this.$emit("onSelect", this.user._id);
    }
  }
};
</script>

<style lang="scss" scoped></style>
